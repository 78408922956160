.container{
    width: 100vw;
    overflow-x: hidden;
}

.top{
    height: 25vh;
    background:#FFCC35;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    padding-top: 70px;
}

.content_container{
    min-height: 40vh;
    padding-inline: 9vw;
    padding-top: 20px;
    padding-bottom: 50px;

}

.content_container .ql-align-right{
    text-align: right;
}

.content_container .ql-align-center{
    text-align: center;
}


.content_container ul{
    list-style: disc;
    margin-left: 10px;
}


@media only screen and (max-width: 700px){
    .content_container{
        padding-inline: 15px;
    }

    .content_container ul{
        list-style:disc;
        /* padding-right: 10px; */
        margin-left: 10px;
    }
}