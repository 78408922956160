.hero_container {
	position: relative;
	height: 100vh;
	overflow: hidden;
	padding-top: 0;
	display: flex;
	align-items: center;
}

.video {
	height: 100vh;
	width: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -10;
}

.hero_data_container{
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100vw;
	color: #fcfcfc;
	z-index: 20;
	opacity: .6;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border: '2px solid red';
}

@media only screen and (max-width: 768px) {
	.hero_container {
		height: 100vh;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.video {
		height: 100vh;
		width: 100vw;
		object-fit: cover;
		top: 0;
		left: 0;
		right: 0;
	}
}
