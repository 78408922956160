.card {
	width: 100%;
	aspect-ratio: 1/1.05;
	position: relative;
}

.card img {
	width: 100%;
	height: 100%;
}

.card_tag {
	position: absolute;
	background: rgba(255, 255, 255, 0.7);
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px;
}

.cards_container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
	padding: 0 9vw 50px 9vw;
}

.value{
	font-size: 2.5rem;
}

@media only screen and (max-width: 700px) {
	.cards_container {
		grid-template-columns: 1fr;
	}
	.value{
		font-size: 1.3rem;
	}
}
