.card_container{
  display: grid;
  grid-template-columns: repeat( auto-fit , minmax(250px,1fr));
  gap:15px;
}

.card {
  width: 100%;
  aspect-ratio: 1/1 !important;
  background: transparent;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.service_card_container{
  flex-grow: 1;
  padding-right: 12vw;
}
.img_container{
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card img {
  height: 150px;
  width: 150px;
}

.card_content {
  list-style: disc;
  font-size: 0.8rem;
  gap: 2px;
  z-index: 100;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  color: white;
 
}

.card_title {
  text-align: center;
  color: black;
  opacity: 100;
  height: 40px;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 5px;
}

.start_up_img{
  aspect-ratio: 16/9;
}

@media only screen and (max-width: 1440px) {
  .card_container{
    grid-template-columns: repeat( auto-fit , minmax(200px,1fr));
  }
}

@media only screen and (max-width: 1024px){
  .card_container{
    grid-template-columns: repeat( auto-fit , minmax(150px,1fr));
  }
}



@media only screen and (max-width: 1024px) {
  .card img {
    position: absolute;
    height: 50%;
    width: 50%;
  }

  .card_title {
    margin-bottom: 5px;
    font-size: 8px;
    line-height: 12px;
  }

  .card_content {
    font-size: 9px;
    padding: 0 15px;
    line-height: 10px;
  }
  
  .service_card_container{
    flex-grow: 1;
    padding-right: 0;
  }
}



@media only screen and (max-width: 400px) {
  .card_title{
    font-size: .9rem;
  }

  .card_content{
    font-size: .6rem;
    line-height: .8rem;
  }
  
}

@media only screen and (max-width: 300px) {
  .card_title{
    font-size: 7px;
  }

  .card_content{
    font-size: 7px;
    line-height: 8px;
  }
}