.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 100px;
  margin-left: 3rem;
  margin-right: calc(3rem + 90px);
  width: 100%;
}

.card_container {
  width: 100%;
  /* height: 250px; */
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 10px;
  background: white;
  color: black;
  width: 80%;
  padding-top: 120px;
  text-align: justify;
}

.img_part {
  width: 70%;
  height: 190px;
  top: -90px;
  right: 10%;
  background: green;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .wrapper {
    grid-template-columns: 1fr;
    margin-left: 0;
    gap: 140px;
  }
  .content {
    width: 100%;
  }
  .img_part{
    right: 0;
  }
}