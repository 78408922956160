@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 400;
	font-size: 100%;
}

.splide__arrow.splide__arrow--prev {
	left: -9vw;
	border-radius: 0;
	height: 50px;
	width: 40px;
}

.splide__arrow.splide__arrow--next {
	right: -9vw;
	border-radius: 0;
	height: 50px;
	width: 40px;
}

#blog .splide__arrow.splide__arrow--prev {
	left: -24px;
	border-radius: 0;
	height: 50px;
	width: 40px;
}

#blog .splide__arrow.splide__arrow--next {
	right: -1.5rem;
}


.ql-align-right{
	text-align: right;
}

.ql-align-center{
	text-align: center;
}


#blog_data h2{
	font-size: 1.5rem !important;
	font-weight: 500 !important;
}

#blog_data h1{
	font-size: 1.7rem !important;
	font-weight: 500 !important;
  }
  
  #blog_data h3{
	font-size: 1.3rem !important;
	font-weight: 500 !important;
  }
  
  #blog_data h4{
	font-size: 1.1rem !important;
	font-weight: 500 !important;
  }

/* identifier_blog_title.jpeg */