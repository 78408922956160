.content_container {
  width: calc(100vw - 18vw);
  min-height: 100vh;
  height: fit-content;
  background: white;
  margin: 0 auto;
  transform: translateY(-200px);
  font-size: 18px;
  color: #023033;
}

.content_container img {
  width: 100%;
}



.content_quill {
  white-space: pre-line !important;
}

.content_quill img {
  width: 500px;
  height: 300px;
  /* aspect-ratio: 16/9; */
  object-fit: cover;
  object-position: left;
}


.blog_title{
  color: white;
  font-weight: 500;
  font-size: 2rem;
}

@media (max-width: 780px) {
  .content_container {
    font-size: 16px;
  }

  .content_quill img {
    height: fit-content;
    width: 200px;
    object-fit: contain;
    object-position: center;
  }

  .blog_title{
    color: white;
    font-weight: 500;
    font-size: 1.3rem;
    padding-inline: 9vw;
    text-align: center;
  }
}
