.grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 50px 9vw;
  gap: 20px;
  row-gap: 32px;
}

.hero_container{
  position: relative;
}

.hero_container div{
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.3);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 600;
}

.loader {
  width: 30px;
  height: 30px;
  border: 4px solid #FFCC35;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

@media (max-width: 480px) {
  .grid_container {
    grid-template-columns: 1fr;
  }
  .hero_container div{
    font-size: 1.8rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .grid_container {
    grid-template-columns: 1fr 1fr;
  }
  .hero_container div{
    font-size: 2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .grid_container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
}
