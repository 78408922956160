.content_wrapper {
  padding-top: 5vh;
  /* margin-left: 3.5rem; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.content_wrapper img{
  height: 100%;
}

.content_text{
  width: 100%;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.service {
  display: flex;
  flex-direction: column;
  padding: 40px 12vw;
  gap: 20px;
}

.service > p {
  color: #cd9803;
  text-transform: uppercase;
}

.service_card {
  width: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_card_overlay {
  position: absolute;
  z-index: -9;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

.service_card img {
  position: absolute;
  object-fit: cover;
  object-position: center;
  z-index: -10;
  width: 100%;
  height: 100%;
}

.service_card_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.card_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  padding: 80px 12vw;
}

.card {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  width: 100%;
  position: relative;
  border: 2px solid #ef8600;
  padding-top: 40px;
}

.card img {
  position: absolute;
  height: 90px;
  border-radius: 100%;
  top: 0;
  right: 0;
  transform: translateX(40%) translateY(-40%);
}

.card div {
  padding: 15px;
}

.card_container_mob {
  display: none;
}

.card_para{
  font-size: 1rem;
  text-align: justify;
}

.contact{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 150px;
}

.contact p, .contact a{
  font-size: .9rem;
}

@media only screen and (max-width: 1024px){

  .content_text{
    font-size: 1rem;
  }

  .card_para{
    font-size: .8rem;
  }
  
}

@media only screen and (max-width: 700px) {

  .contact{
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .content_wrapper {
    grid-template-columns: 1fr;
    margin-left: 0;
  }

  .content_wrapper img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }

  .content_wrapper p {
    font-size: 1rem;
  }

  .card_container_mob {
    display: block;
    padding: 50px 9vw 30px 9vw;
  }

  .card_container {
    display: none;
  }

  .card {
    margin-top: 45px;
    height: fit-content;
  }

  .card img {
    right: 50%;
    top: 0;
    z-index: 100;
    transform: translate(50%, -50%);
  }

  .service_card_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .service {
    padding: 40px 9vw;
  }

  .service_card p {
    font-size: 0.8rem;
  }
}


